import { useQuery, useQueryClient } from '@tanstack/vue-query'
import type { ILeaseSummary } from '@register'
import type { Ref, MaybeRef } from 'vue'

export function invalidateApiLeaseSummary(
  divisionId: MaybeRef<string>,
  leaseId: MaybeRef<string>,
) {
  const queryClient = useQueryClient()
  queryClient.invalidateQueries({
    queryKey: getApiLeaseSummaryKey(divisionId, leaseId),
  })
}

export function getApiLeaseSummaryKey(
  companyId: MaybeRef<string | undefined>,
  leaseId: MaybeRef<string | undefined>,
) {
  return ['companies', companyId, 'leases', leaseId, 'summary']
}

export const useApiLeaseSummary = (
  companyId: string,
  leaseId: MaybeRef<string>,
  enabled: Ref<boolean> = ref(true),
) => {
  const { fields } = storeToRefs(useSummaryStore())
  const query = useQuery({
    queryKey: getApiLeaseSummaryKey(companyId, leaseId),
    queryFn: ({ signal }) =>
      fetchAPI<ILeaseSummary>(
        `/companies/${companyId}/leases/${unref(leaseId)}/summary`,
        { signal },
      ),
    staleTime: 0,
    enabled: enabled,
  })

  watchEffect(() => {
    if (query.data.value?.leaseRegister) {
      fields.value = [
        ...(query.data.value.leaseRegister.fields ?? []),
        ...(query.data.value.leaseRegister.clausesAndDefinitions ?? []),
      ]
    }
  })

  return query
}
